body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-dev-portfolio-builder footer a, .react-dev-portfolio-builder footer a:visited {
  color: #ffa500 !important;
  text-decoration: none !important;
}
.react-dev-portfolio-builder footer a:hover {
  text-decoration: underline !important;
}
.react-dev-portfolio-builder .tech-icons img{
  border-radius: 50%;
  height:50px;
  width: 50px;
  margin: 0 1rem;
}
.react-dev-portfolio-builder .card {
  display: flex !important;
}
.react-dev-portfolio-builder .card .card-img{
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover !important;
}
.react-dev-portfolio-builder .about-gallery img{
  margin: 10px;
  height: 300px;
  display: inline-block;
  max-width: 95%;
}
